import React from 'react';
import {ReactComponent as Logo} from './shared/assets/logo-red.svg'
import TelegramLogo from './shared/assets/icons/telegramIcon.svg'
import InstagramLogo from './shared/assets/icons/instagramIcon.svg'
import WhatsappLogo from './shared/assets/icons/whatsappIcon.svg'
import './App.css';

type SocialLinkType = {
    iconSrc: string,
    altText: string,
    linkText: string,
    link: string,
}

const SocialLink = ({ iconSrc, altText, linkText, link }: SocialLinkType) => (
    <div className="social-link">
        <img src={iconSrc} alt={altText} className="social-icon" />
        <a href={link} className="social-text">{linkText}</a>
    </div>
);

const socialList: SocialLinkType[] = [
    {
        iconSrc: InstagramLogo,
        altText: 'instagram',
        linkText: 'Instagram',
        link: 'https://www.instagram.com/lublu.cake'
    },
    {
        iconSrc: TelegramLogo,
        altText: 'telegram',
        linkText: 'Telegram',
        link: 'https://t.me/LubluCake',
    },
    {
        iconSrc: WhatsappLogo,
        altText: 'whatsapp',
        linkText: 'Whatsapp',
        link: 'https://wa.me/message/ZKFQDIK536JJC1'
    },
]

function App() {
    return (
        <div className="wrapper">
            <div className="content">
                <Logo width={300} height={250} />
                <div style={{
                    maxWidth: '100%'
                }}>
                    {socialList.map(
                        ({iconSrc, linkText, altText, link}) =>
                            <SocialLink
                                iconSrc={iconSrc}
                                altText={altText}
                                linkText={linkText}
                                link={link}
                            />
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
